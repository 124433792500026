<template>
  <div class="Tabs">
    <div
      class="Tab"
      :class="{ 'Tab--Active': tab.id == activeTab, }"
      v-for="(tab, i) in tabs"
      :key="i"
      :id="tab.id"
      v-on:click="handleTabChange(tab.id)">
      {{ $t(tab.title) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',

  props: {
    tabs: {
      type: Array,
      default: [],
    },
    activeOnLoad: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    activeTab: null,
  }),

  created() {
    if( this.tabs.length > 0 && !this.activeOnLoad ) {
      this.handleTabChange( this.tabs[0].id );
    } else if ( this.activeOnLoad ) {
      this.handleTabChange( this.activeOnLoad );
    }
  },

  methods: {
    handleTabChange(tabId) {
      this.activeTab = tabId;
      this.$emit('change', tabId);
    },

    reset(){
      this.activeTab = this.tabs[0].id
    },
  }
}
</script>

<style scoped lang="scss">
.Tabs {
  padding: 15px;
  display: flex;

  .Tab {
    margin-right: 12px;
    cursor: pointer;

    &--Active {
      color: var(--primary-color-text);
    }
  }
}
</style>
