<template lang="html">
  <div class="CommunityUserAssignment">
    <div class="CommunityUserAssignment--Panel">
      <div class="CommunityUserAssignment--Types">
        <Tabs class="addParticipants__type" :tabs="assignmentTypes" :activeOnLoad="assignmentTypes[0].id" v-on:change="handleChangeActive" style="padding:0px" />
      </div>
      <BoxShadow class="CommunityUserAssignment--Box" v-show="addParticipantsActive == 0">
        <SearchBar class="SearchBarV2" :placeholder="$t('find_by_name_or_tags')" :autocompleteListItems="tags" v-on:item-selected="handleItemSelectedToAdd($event)"/>
        <div class="CommunityUserAssignment--Tags" v-show="filterTags.length > 0">
          <Tag :name="t" class="TagV2" v-for="(t,i) in filterTags" :isClickable="true" :isActive="true" v-on:remove="removeFilterTag(i,'all')"/>
        </div>
        <div class="CommunityUserAssignment--UsersCount">
          <p><span v-if="getMembers().length != members.all.length">Mostrados {{getMembers().length}} de </span>{{members.all.length}} usuarios</p>
          <CommunityButton v-on:click="addAllMembers()">{{$t('user_assignment_add_all')}} <span>|</span> <i class="fas fa-user"></i> {{getMembers().length}}</CommunityButton>
        </div>
        <p class="CommunityUserAssignment--Total">Total de usuarios: {{members.all.length}}</p>
        <Separator />
        <div class="CommunityUserAssignment--List">
          <CommunityUser :hideGamification="hideGamification" :member="m" v-for="(m,i) in getMembers(true)" :allowAdd="true" :allowRemind="false" v-on:add="addMember(m,i)"/>
        </div>
      </BoxShadow>
      <BoxShadow class="CommunityUserAssignment--Box" v-show="addParticipantsActive == 1">
        <CommunityInput type="textarea" v-model="participantsIds" :placeholder="'Ej.: 1234,5678,9999'" />
        <div class="CommunityUserAssignment--UsersCount">
          <CommunityButton v-on:click="cleanMembersIdList()">{{$t('user_assignment_by_ids_clean')}}</CommunityButton>
          <CommunityButton v-on:click="addMembersByIds()">{{$t('user_assignment_by_ids')}}</CommunityButton>
        </div>
      </BoxShadow>
    </div>
    <div class="CommunityUserAssignment--Panel" style="margin-top:18px">
      <h1>{{$t('participants')}}</h1>
      <BoxShadow class="CommunityUserAssignment--Box">
        <div class="CommunityUserAssignment--Added" v-if="members.added.length > 0">
          <p>{{members.added.length}}</p>
          <p>{{$t('user_assignment_participants_added')}}</p>
          <CommunityButton v-on:click="$emit('send-email')" :disabled="members.added.length == 0" v-if="emailButton">{{$t('custom_mail_send_usernum',{userNum:members.added.length})}}</CommunityButton>
          <CommunityButton v-on:click="$emit('send-notification')" :disabled="members.added.length == 0" v-if="notificationButton">{{$t('custom_notification_send_usernum',{userNum:members.added.length})}}</CommunityButton>
        </div>
        <SearchBar class="SearchBarV2" :placeholder="$t('find_by_name_or_tags')" :autocompleteListItems="tags" v-on:item-selected="handleItemSelected($event)"/>
        <div class="CommunityUserAssignment--Tags" v-show="filterTags.length > 0">
          <Tag :name="t" class="TagV2" v-for="(t,i) in filterTagsAdded"  :isClickable="true" :isActive="true" v-on:remove="removeFilterTag(i,'added')"/>
        </div>
        <div class="CommunityUserAssignment--UsersCount">
          <p>{{getAddedMembers().length}} {{$t('users')}}</p>
          <CommunityButton class="delete" :disabled="getAddedMembers().length == 0" v-on:click="deleteAllMembers()">{{$t('user_assignment_remove_all')}} <span>|</span> <i class="fas fa-user"></i> {{getAddedMembers().length}}</CommunityButton>
        </div>
        <Separator />
        <div class="CommunityUserAssignment--List column">
          <CommunityUser :hideGamification="hideGamification" :member="m" v-for="(m,i) in getAddedMembers(true)"  :allowDelete="true" :allowRemind="false" :reduced="true" v-on:delete="deleteAddedMember(m)"/>
          <p v-if="members.added.length == 0">{{$t('user_assignment_participants_no_added')}}</p>
        </div>
      </BoxShadow>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import CommunityUser from '@/components/CommunityUser'
import BoxShadow from '@/components/BoxShadow'
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import Separator from '@/components/Separator'
import CommunityButton from '@/components/CommunityButton'
import Tag from '~/components/Tag';
import Tabs from "~/components/Tabs";
import CommunityInput from '@/components/CommunityInput';

export default {
  name:'CommunityUserAssignment',
  components:{
    CommunityUser,
    BoxShadow,
    SearchBar,
    Separator,
    CommunityButton,
    Tag,
    Tabs,
    CommunityInput
  },
  data(){
    return{
      filterTags:[],
      filterNickname:"",
      filterTagsAdded:[],
      filterNicknameAdded:"",
      limit:200,
      allMembers: [],
      assignmentTypes: [
        { id: 0, title: "add_participants_by_nicknames" },
        { id: 1, title: "add_participants_by_ids" },
      ],
      addParticipantsActive: 0,
      participantsIds: "",
      participantsIdsList: [],
      debounceTimeoutToAdd: null,
      debounceTimeout: null
    }
  },
  props: {
    members:{
      all:Array,
      filtered:Array,
      filteredAdded:Array,
      added:Array,
    },
    tags:Array,
    emailButton:Boolean,
    notificationButton: Boolean,
    hideGamification: {
      type: Boolean,
      default:false
    }
  },
  methods:{
    handleChangeActive: async function(e) {
      this.addParticipantsActive = e;
    },
      addMembersByIds() {
          // Convertir la cadena de IDs en un array, eliminando espacios innecesarios.
          this.participantsIdsList = this.participantsIds.split(/\s*,\s*/);

          // Sets para almacenar los distintos tipos de IDs.
          const alreadyAddedIds = new Set(); // IDs que ya estaban en `members.added`.
          const nonExistentIds = new Set();  // IDs que no existen en `members.all`.

          // Recorrer los IDs introducidos por el usuario.
          for (let id of this.participantsIdsList) {
              // Buscar en members.all si existe el ID.
              const member = this.members.all.find(m => String(m.identifier) === String(id));

              // Si el ID no existe en members.all, agregarlo a nonExistentIds.
              if (!member) {
                  nonExistentIds.add(id);
              } else if (this.members.added.includes(member.identifier)) {
                  // Si el ID ya está añadido, agregarlo a alreadyAddedIds.
                  alreadyAddedIds.add(member.identifier);
              } else {
                  // Si el ID no está en members.added, agregarlo.
                  this.members.added.push(member.identifier);
              }
          }

          // Mostrar un mensaje de alerta con Swal si hay IDs que no se pudieron añadir.
          if (alreadyAddedIds.size > 0 || nonExistentIds.size > 0) {
              let message = '';

              // Si hay IDs que ya se añadieron anteriormente, agregarlos al mensaje
              if (alreadyAddedIds.size > 0) {
                  message += `${this.$t('already_added_ids')}<br>${Array.from(alreadyAddedIds).join(', ')}<br><br>`;
              }

              // Si hay IDs que no existen en `members.all`, agregarlos al mensaje
              if (nonExistentIds.size > 0) {
                  message += `${this.$t('non_existent_ids')}<br>${Array.from(nonExistentIds).join(', ')}`;
              }

              Swal.fire({
                  icon: 'warning',
                  title: this.$t('users_not_added'),
                  html: message,
                  confirmButtonText: this.$t('understood')
              });
          }
      }
,
    cleanMembersIdList() {
      this.participantsIds = ""
      this.participantsIdsList = []
    },
    removeFilterTag(i,type){
      if(type == 'all'){
        this.filterTags.splice(i,1)
        this.filterAllMembers()
      }
      else{
        this.filterTagsAdded.splice(i,1)
        this.filterAddedMembers()
      }
    },
    handleItemSelectedToAdd(filter) {
      clearTimeout(this.debounceTimeoutToAdd);
      this.debounceTimeoutToAdd = setTimeout(() => {
        this.filterAllMembers(filter);
      }, 500);
    },
    filterAllMembers(filter){
      if(filter != undefined && filter.text != "" && filter.type == 'filter'){
        this.filterTags.push(filter.text)
        this.filterNickname = ""
      }
      if(filter != undefined && filter.text != "" && filter.type == 'nickname') this.filterNickname = filter.text
      if(filter != undefined && filter.text == "") this.filterNickname = ""
      let data = {
        filter:this.filterTags.join(','),
        nickname:this.filterNickname != "" ? this.filterNickname : undefined
      }
      this.$emit('filter-all',data)
    },
    handleItemSelected(filter) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.filterAddedMembers(filter);
      }, 500);
    },
    filterAddedMembers(filter){
      if(filter != undefined && filter.type == 'filter'){
        this.filterTagsAdded.push(filter.text)
        this.filterNicknameAdded = ""
      }
      if(filter != undefined && filter.type == 'nickname') this.filterNicknameAdded = filter.text
      let data = {
        filter:this.filterTagsAdded.join(','),
        nickname:this.filterNicknameAdded != "" ? this.filterNicknameAdded : undefined
      }
      this.$emit('filter-added',data)
    },
    getMembers(withLimit){
      let members = []
      let allMembers = []
      let limit = 0
      for(let m of this.members.all){
        if(limit < this.limit){
          if(!this.members.added.includes(m.identifier)){
            if(this.members.filtered.length > 0 && this.members.filtered.length < this.limit) withLimit = false
            if(this.members.filtered.length > 0 || this.filterNickname != "") {
              if(this.members.filtered.includes(m.identifier)) members.push(m)
            }
            else members.push(m)
          }
          if(withLimit)limit++
        }
      }
      return members
    },
    getAddedMembers(withLimit){
      let members = []
      for(let m of this.members.all){
        if(this.members.added.includes(m.identifier) && (this.limit > members.length || !withLimit)){
          if(this.members.filteredAdded.length > 0 || this.filterNicknameAdded != "") {
            if(this.members.filteredAdded.includes(m.identifier)) members.push(m)
          }
          else members.push(m)
        }
      }
      return members
    },
    addMember(member, index){
      this.members.added.push(member.identifier)
    },
    deleteAddedMember(member){
      for(let i in this.members.added){
        if(this.members.added[i] == member.identifier) this.members.added.splice(i,1)
      }
    },
    addAllMembers(){
      for(let m of this.getMembers()){
        this.members.added.push(m.identifier)
      }
    },
    deleteAllMembers(){
      for(let m of this.getAddedMembers()){
        this.members.added.splice(this.members.added.indexOf(m.identifier),1)
      }
    },
  }
}
</script>
<style lang="scss">
.CommunityUserAssignment{
  display: grid;
  grid-gap: 15px;
  grid-template-columns: calc(64% - 7.5px) calc(36% - 7.5px);

  @media only screen and (max-width: 600px) {
    display: block;
    &--Box{
      margin: 25px 0;
    }
  }

  &--Panel:last-of-type{
    button.delete{
      background: linear-gradient(255.48deg, #820509 10.29%, #BE0007 89.71%)!important;
    }
  }
  &--Tags{
    margin: 20px 0;
  }
  &--Added{
    padding: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p:first-child{
      font-weight: bold;
      font-size: 51px;
      text-align: center;
      letter-spacing: -0.07em;
      color: var(--accent-color);
    }
    p:last-of-type{
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #3E3E3E;
    }
    button{
      font-size: 13px!important;
      margin-top: 10px;
      background: var(--accent-color)!important;
    }
  }
  &--Box{
    flex-direction: column;
  }
  &--List{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(2,calc(50% - 8px));
    // grid-gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
      border-radius: 10px;
      height: 20px;
    }
    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 2px;
      border-radius: 10px;
      height: 20px;

    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      height: 20px;

    }
    p{
      font-size: 19px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #969696;
      opacity: 0.6;
    }
    .CommunityUser{
      flex: 1 1 267px;
      margin-bottom: 14px;
      margin-right: 14px;
    }
    &.column{
      flex-direction: column;
      flex-wrap: nowrap;
      .CommunityUser{
        flex: none;
        margin: 0 5px 5px 0;
      }
    }
  }
  &--Types{
    .addParticipants__type .Tab {
      padding: 10px;
      margin-right: 0;
      &--Active {
        background: rgb(255, 255, 255);
        border: 1px solid #E1E1E1;
        font-weight: bold;
      }
    }
  }
  &--Total{
    font-size: 19px;
  }
  &--UsersCount{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 11px 0 0 0;
    flex-wrap: wrap;
    p{
      font-size: 19px;
    }
    button{
      font-size: 13px!important;
      background: var(--accent-color)!important;
      span{
        margin:0 8px;
      }
      i{
        margin-right: 8px;
      }
    }
  }
  h1{
    font-size: 19px;
    letter-spacing: -0.01em;
    color: #3E3E3E;
  }
}
</style>
